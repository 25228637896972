export class ArrayUtil {
	static containsStringInArray(list: string[], text: string) {
		return Array.isArray(list) && list.some(url => text.includes(url));
	}

	//crate a method isEmpty and check array is emptty
	static isEmpty(value: any[]): boolean {
		return Array.isArray(value) && value.length === 0;
	}
}
