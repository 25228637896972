export class DateUtil {
	private static getExpirationList(dateDDMM: string): number[] {
		return dateDDMM.split('/').map(it => Number(it));
	}

	private static getExpirationListDefaulValue(dateDDMM: string): string[] {
		return dateDDMM.split('/').map(it => it);
	}

	static yearYYYY(dateDDMM: string): number {
		const dateTmp = new Date();
		const oldYY = Number(String(dateTmp.getFullYear()).substr(2));
		const yearFull = dateTmp.getFullYear() - oldYY;

		return Number(DateUtil.getExpirationList(dateDDMM).pop()) + yearFull;
	}

	static monthMM(dateDDMM: string): number {
		return Number(DateUtil.getExpirationList(dateDDMM).shift());
	}

	static monthMMDefault(dateDDMM: string): string {
		return DateUtil.getExpirationListDefaulValue(dateDDMM).shift();
	}

	static dateMMDDDD(dateDDMM: string): string {
		return `${DateUtil.monthMMDefault(dateDDMM)}/${DateUtil.yearYYYY(dateDDMM)}`;
	}

	static formatDate(date: string): Date {
		const list = date.split(' ');
		let dateCalendar = list.shift();
		const timeHours = list.pop() || '11:00:00';

		if (dateCalendar.includes('/')) {
			dateCalendar = dateCalendar.split(' ').shift()?.split('/').reverse().join('-');
		}

		const dateInList = `${dateCalendar}-${timeHours}`.split('-').join().split(':').join().split(',');

		const year = Number(dateInList.shift());
		const month = Number(dateInList.shift()) - 1;
		const hour = Number(dateInList.shift());
		const minute = Number(dateInList.shift());
		const second = Number(dateInList.shift());
		const milisecond = Number(dateInList.shift());

		return new Date(year, month, hour, minute, second, milisecond);
	}

	static newDate(date: string): Date {
		if (!date) {
			return new Date();
		}

		if (date.includes('/')) {
			const dateString = date.split('/').reverse().join('-');
			return DateUtil.formatDate(dateString);
		}
		return DateUtil.formatDate(date);
	}

	static formatAmerican(datePtBr: string): string {
		const date = DateUtil.newDate(datePtBr);
		return date.toISOString().split('T').shift() || '';
	}

	static formatDateString(date: string): string {
		if (date.includes('-')) {
			return date.split(' ').shift()?.split('-').reverse().join('/');
		}
		if (date.includes('/')) {
			return date.split(' ').shift()?.split('/').reverse().join('-');
		}

		return date;
	}

	static isOld(date: Date): boolean {
		if (isNaN(Date.parse(date.toString()))) {
			return false;
		}

		return date < new Date();
	}

	static isOldDisregardHours(date: Date): boolean {
		if (isNaN(Date.parse(date.toString()))) {
			return false;
		}

		return new Date(date.setHours(10, 0, 0, 0)) <= new Date(new Date(10, 0, 0, 0));
	}

	static isValid(date: string): boolean {
		const d = DateUtil.newDate(date);
		return !isNaN(d.getTime());
	}
}
