//api: 'https://backoffice.homolog.movetogo.com.br/json_client',
//api: 'https://backoffice.move.butec.com.br/json_client',
//api: 'https://backoffice.movetogo.com.br/json_client',
//googleAnalytcs: 'UA-49786821-19',
export const environment = {
	production: true,
	api: 'https://backoffice.movetogo.com.br/json_client',
	googleAnalytcs: 'UA-49786821-19',
	iugu: {
		api: 'https://api.iugu.com/v1',
		accountId: '656276AC5ECD49ED887884887B09A8C4',
		modoTeste:false
	}
};
