export class CouponDto {
	mensagem: string;
	tipoCupom: string;
	valor: string;
	name: string;

	constructor(mensagem: string, tipoCupom: string, valor: string, name: string);
	constructor(obj?: Object);
	constructor(...args: any[]) {
		this.mensagem = args[0] || '';
		this.tipoCupom = args[1] || '';
		this.valor = args[2] || '';
		this.name = args[3] || '';

		if (args.length === 1 && args[0] instanceof Object) {
			this.deserializeObj(args[0]);
		}
	}

	isEmpty(): boolean {
		return !(this.mensagem && this.tipoCupom && this.valor && this.name);
	}

	private deserializeObj(instanceData: Object) {
		const keys = Object.keys(this);

		for (const key of keys) {
			if (instanceData.hasOwnProperty(key)) {
				this[key] = instanceData[key];
			}
		}
	}
}
